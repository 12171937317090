import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import * as React from 'react';

import config from '../../config.json';
import {
  BackgroundTexture,
  BalloonIcon,
  BusinessCardIcon,
  CalendarIcon,
  Carousel,
  DriftLogo,
  HourglassIcon,
  Layout,
  SEO,
  SideBySide,
} from '../components';
import { ContactFormWithMap } from '../components/contact-form-with-map';
import { HeadingWithIcon } from '../components/heading-with-icon';
import { SideImage } from '../components/side-image';
import { useGraphQL } from '../hooks';

function IndexPage({
  data: {
    allSanityBannerImages: { nodes },
  },
}): React.ReactElement {
  const { agm2Notice, electionNotice, noticeOfElection } = useGraphQL();
  return (
    <Layout hero={<Hero nodes={nodes} />}>
      <SEO title="Home" />
      <h1 className="sr-only">{config.title}</h1>
      <WhatsOn />
      <EatAndDrink />
      <HavingAFunction />
      <MemberBenefits />
      {/* <div className="relative flex flex-wrap w-full px-4 mx-auto space-y-6 md:space-y-0 max-w-screen-2xl sm:px-6 lg:px-8">
        <div className="w-full md:w-1/2">
          <GatsbyImage
            image={agm2Notice.childImageSharp.gatsbyImageData}
            className="flex-1"
            alt="AGM"
            objectFit="contain"
          />
        </div>
        <div className="w-full md:w-1/2">
          <GatsbyImage
            image={noticeOfElection.childImageSharp.gatsbyImageData}
            className="flex-1"
            alt="Election"
            objectFit="contain"
          />
        </div>
      </div> */}
      <OurStory />
      <ContactFormWithMap background="blue" />
    </Layout>
  );
}

function Hero({ nodes }): React.ReactElement {
  return (
    <Carousel>
      {nodes[0].carouselImages.map((img) => (
        <li key={img.asset._id} className="flex">
          <GatsbyImage
            image={
              getGatsbyImageData(
                img.asset,
                { placeholder: 'dominantColor' },
                { projectId: 'q45hh4q1', dataset: 'production' }
              ) as IGatsbyImageData
            }
            className="flex-1"
            alt=""
          />
        </li>
      ))}
    </Carousel>
  );
}

function WhatsOn(): React.ReactElement {
  const {
    whatsOnHero,
    landingBingo,
    landingRaffle,
    landingWeekendVibes,
  } = useGraphQL();
  return (
    <article className="relative py-12">
      <BackgroundTexture insetTop="full" insetBottom="full" />
      <div className="relative">
        <SideBySide
          sideComponent={
            <SideImage
              image={
                whatsOnHero.childImageSharp.gatsbyImageData as IGatsbyImageData
              }
            />
          }
        >
          <div className="w-full mx-auto max-w-prose">
            <HeadingWithIcon>
              <HeadingWithIcon.Heading>
                What’s
                <br />
                On
              </HeadingWithIcon.Heading>
              <HeadingWithIcon.Icon hidden>
                <CalendarIcon />
              </HeadingWithIcon.Icon>
            </HeadingWithIcon>
            <h3 className="mt-5 text-blue-dark heading-3">
              Happy Hour | Monday 5pm – 6pm
            </h3>
            <div className="mt-5 prose">
              <p>Join us for Happy Hour Monday through to Thursday.</p>
              <p>
                Members of the Club can enjoy $5.50 schooners and house wines
                from 5pm to 6pm.
              </p>
            </div>
            <p className="mt-4">
              <Link
                to="/whats-on/"
                className="inline-flex items-center justify-center button"
              >
                Find out more
              </Link>
            </p>
            <p className="mt-4">
              <Link
                to="/renovations/"
                className="inline-flex items-center justify-center button"
              >
                Read about our renovations
              </Link>
            </p>
          </div>
        </SideBySide>
        <div className="relative w-full px-4 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
          <div className="grid gap-12 mt-12 lg:grid-cols-3">
            {[
              {
                heading: 'Bingo',
                image: landingBingo.childImageSharp.gatsbyImageData,
                route: '/whats-on/promotions/',
                ctaLabel: 'Let’s Play Bingo',
              },
              {
                heading: 'Weekend Vibes',
                image: landingWeekendVibes.childImageSharp.gatsbyImageData,
                route: '/whats-on/events-entertainment/',
                ctaLabel: 'Live Entertainment',
              },
              {
                heading: 'Raffles',
                image: landingRaffle.childImageSharp.gatsbyImageData,
                route: '/whats-on/promotions/',
                ctaLabel: 'View Our Weekly Raffles',
              },
            ].map(({ heading, image, route, ctaLabel }) => (
              <article
                key={heading}
                className="bg-blue-light aspect-w-16 aspect-h-8 xs:aspect-h-6 xs:aspect-w-16 lg:aspect-w-4 lg:aspect-h-3"
              >
                <div className="flex">
                  <GatsbyImage image={image} className="flex-1" alt="" />
                </div>
                <div className="flex items-center justify-center text-center bg-black bg-opacity-50">
                  <div className="relative flex flex-col p-4">
                    <h3 className="text-white heading-2 text-shadow">
                      {heading}
                    </h3>
                    <Link to={route} className="mt-2 button">
                      {ctaLabel}
                    </Link>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </article>
  );
}

function EatAndDrink(): React.ReactElement {
  const { landingEatDrink } = useGraphQL();
  return (
    <div className="py-12">
      <SideBySide
        isReversed={false}
        sideComponent={
          <SideImage
            image={
              landingEatDrink.childImageSharp
                .gatsbyImageData as IGatsbyImageData
            }
          />
        }
      >
        <div className="flex h-full py-8 bg-blue-light text-blue-dark">
          <div className="flex flex-col justify-center w-full p-4 pt-8 mx-auto max-w-prose">
            <HeadingWithIcon>
              <HeadingWithIcon.Heading>
                Eat &amp;
                <br />
                Drink
              </HeadingWithIcon.Heading>
              <HeadingWithIcon.Icon>
                <DriftLogo />
              </HeadingWithIcon.Icon>
            </HeadingWithIcon>
            <h3 className="mt-2 heading-3">Drift bar.cafe.restaurant</h3>
            <div className="mt-2 prose">
              <p>Drift is a place we have created with you in mind…</p>
              <p>
                The perfect space for a quiet coffee and catch up with friends
                to a lovely night out the whole family can enjoy. Now is the
                perfect time to join us at your local Club, Port City!
              </p>
              <p>
                Open daily for lunch and dinner, remember bookings are essential
                so give us a call and reserve your table today!
              </p>
            </div>
            <p className="mt-4">
              <Link
                to="/eat-and-drink/drift-bar-cafe-restaurant/"
                className="inline-flex items-center justify-center w-56 button"
              >
                See Our Menu
              </Link>
            </p>
          </div>
        </div>
      </SideBySide>
    </div>
  );
}

function HavingAFunction(): React.ReactElement {
  const { functions } = useGraphQL();
  return (
    <div className="relative py-12">
      <BackgroundTexture overflowBottom insetBottom="full" />
      <div className="relative">
        <SideBySide
          sideComponent={
            <SideImage
              image={
                functions.childImageSharp.gatsbyImageData as IGatsbyImageData
              }
            />
          }
        >
          <div className="flex flex-col justify-center w-full p-4 pt-8 mx-auto max-w-prose">
            <HeadingWithIcon>
              <HeadingWithIcon.Heading>
                Having a <br />
                Function
              </HeadingWithIcon.Heading>
              <HeadingWithIcon.Icon hidden>
                <BalloonIcon />
              </HeadingWithIcon.Icon>
            </HeadingWithIcon>
            <div className="mt-5 prose">
              <p>
                <span className="text-lg font-semibold uppercase text-blue-dark">
                  Corporate{' '}
                </span>
                For conferences, training days/seminars and meetings away from
                the office we are committed to ensuring that every aspect of
                your corporate function is coordinated with precision and
                professionalism.
              </p>
              <p>
                <span className="text-lg font-semibold uppercase text-blue-dark">
                  Barefoot Bowls{' '}
                </span>
                Parties with a difference! We have a variety of barefoot bowls
                deals that everyone can enjoy. Perfect for your next social or
                fundraising event.
              </p>
              <p>
                <span className="text-lg font-semibold uppercase text-blue-dark">
                  Parties{' '}
                </span>
                At Port City we love to celebrate! As your venue partner we
                pride ourselves on offering a friendly and professional approach
                to all your function needs. We will work closely with you to
                ensure that your event is exactly how you imagine it.
              </p>
              <p>
                <span className="text-lg font-semibold uppercase text-blue-dark">
                  Memorials{' '}
                </span>
                Losing a loved one can be a difficult time for families
                especially when having to organise memorial services as well.
                Our functions team can take this stress away and allow your
                family and friends time to celebrate your loved one’s life and
                share many memories together.
              </p>
            </div>
            <p className="mt-4">
              <Link
                to="/functions/"
                className="inline-flex items-center justify-center w-56 button"
              >
                Find Out More
              </Link>
            </p>
          </div>
        </SideBySide>
      </div>
    </div>
  );
}

function MemberBenefits(): React.ReactElement {
  const { membership } = useGraphQL();

  return (
    <div className="py-12">
      <SideBySide
        isReversed={false}
        sideComponent={
          <SideImage
            image={
              membership.childImageSharp.gatsbyImageData as IGatsbyImageData
            }
          />
        }
      >
        <div className="flex h-full py-8 bg-blue-light text-blue-dark">
          <div className="flex flex-col justify-center w-full p-4 pt-8 mx-auto max-w-prose">
            <HeadingWithIcon>
              <HeadingWithIcon.Heading>
                Member
                {/* <span className="block sm:hidden" /> */}
                ship
              </HeadingWithIcon.Heading>
              <HeadingWithIcon.Icon hidden>
                <BusinessCardIcon />
              </HeadingWithIcon.Icon>
            </HeadingWithIcon>
            <div className="mt-2 prose">
              <p>
                Belonging to Port City Bowling Club is more than a membership,
                you become part of a community of fun, fabulous like-minded
                people who enjoy socialising with friends, great food and the
                best of sport.
              </p>
            </div>
            <p className="mt-4">
              <Link
                to="/membership/"
                className="inline-flex items-center justify-center px-6 text-center button xs:text-justify"
              >
                Become a Member Today
              </Link>
            </p>
          </div>
        </div>
      </SideBySide>
    </div>
  );
}

function OurStory(): React.ReactElement {
  const { ourStory1, ourStory2, ourStory3 } = useGraphQL();
  return (
    <div className="xs:py-12">
      <div className="relative">
        <BackgroundTexture insetTop="full" insetBottom="half" />
        <div className="relative">
          <SideBySide
            sideComponent={
              <div className="grid grid-cols-5 gap-4 xs:gap-8">
                <div className="col-span-5 bg-blue-dark aspect-w-16 aspect-h-9">
                  <div className="flex">
                    <GatsbyImage
                      image={
                        ourStory1.childImageSharp
                          .gatsbyImageData as IGatsbyImageData
                      }
                      className="flex-1"
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex col-span-3 bg-blue-dark">
                  <GatsbyImage
                    image={ourStory2.childImageSharp.gatsbyImageData}
                    className="flex-1"
                    alt=""
                  />
                </div>
                <div className="flex col-span-2 bg-blue-dark">
                  <GatsbyImage
                    image={ourStory3.childImageSharp.gatsbyImageData}
                    className="flex-1"
                    alt=""
                  />
                </div>
              </div>
            }
          >
            <div className="flex flex-col justify-center w-full p-4 py-16 mx-auto xl:pt-24 max-w-prose">
              <HeadingWithIcon>
                <HeadingWithIcon.Heading>
                  Our
                  <br />
                  Story
                </HeadingWithIcon.Heading>
                <HeadingWithIcon.Icon hidden>
                  <HourglassIcon className="w-16" />
                </HeadingWithIcon.Icon>
              </HeadingWithIcon>
              <div className="mt-5 prose">
                <p>
                  Following representation from local residents in 1937 the
                  Municipal Council decided to investigate the possibility of
                  constructing the town&lsquo;s first lawn bowling green. The
                  Lands Department of the day in agreement with the Maritime
                  Services Board approved the release of part of the Pilot
                  Station recreation reserve.
                </p>

                <p>
                  This area became the site of the Club first green and original
                  Club house, the cost of developing the green was a total sum
                  of 470 pounds ($940). The cost of the excavation was 20
                  pounds, construction of the green 350 pounds, windmill, and
                  piping and tank 50 pounds, fencing 40 pounds and contingencies
                  10 pounds. Work was commenced in July 1937 and completed in
                  September 1937.
                </p>

                <p>
                  A meeting was held on the 16th December 1937 for the formation
                  of the Port Macquarie Bowling Club...
                </p>
              </div>
              <p className="mt-4">
                <Link
                  to="/about/"
                  className="inline-flex items-center justify-center w-56 button"
                >
                  Read more here
                </Link>
              </p>
            </div>
          </SideBySide>
        </div>
      </div>
    </div>
  );
}

export const graphqlQuery = graphql`
  query BannerQuery {
    allSanityBannerImages {
      nodes {
        carouselImages {
          asset {
            _id
          }
        }
      }
    }
  }
`;

export { IndexPage as default };
